<template>
  <b-modal id="modal-delete-question" hide-footer>
    <div class="allContentModal">
      <div>
        <img class="" src="@/assets/icons/delete-site.svg" alt="alert" />
      </div>
      <div class="spaceText1">
        <p class="text1">Realmente deseja deletar o questionário ?</p>
      </div>
      <div class="spaceText2">
        <p class="text2">Todos as alternativas e respostas serão deletados!</p>
      </div>
      <div class="spaceBtns">
        <button class="button" @click="toCancel">Cancelar</button>
        <button class="button2" @click="toDelete">Deletar</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  props: ["question"],
  components: {},
  methods: {
    toCancel() {
      this.$root.$emit("bv::hide::modal", "modal-delete-question", "#btnShow");
    },
    toDelete() {
      this.$bvModal.hide('modal-delete-question')
      this.$emit('removeQuestion', this.question)
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
#modal-delete-question {
  z-index: 99999999999;
  .modal-header {
    border: none;
  }
}
</style>
<style lang="scss" scoped>
#modal-delete-question {
  input {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    font-size: 14px;
    padding: 20px;
    transition: 0.3s;
  }
  input:hover,
  input:focus {
    border: 1px solid #fc2b36;
    box-shadow: 0px 4px 10px rgba(148, 3, 3, 0.03);
    background: rgba(228, 0, 0, 0.05);
    color: var(--fontcolor);
    font-weight: normal;
  }
  input:focus {
    font-weight: 600;
  }
  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    text-align: initial;
  }
  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
  }
  .spaceText2 {
    margin-bottom: 20px;
  }
  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #fc2b36;
    text-align: center;
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #818181;
  }
  .spaceBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .button {
    background: var(--backgroundcolor);
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    border: none;
    margin: 0 5px;
  }
  .button2 {
    background: #fc2b36;
    border-radius: 6px;
    width: 200px;
    height: 45px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: none;
    margin: 0 5px;
  }
}
</style>