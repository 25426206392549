<template>
  <b-modal id="modal-view-quiz" hide-footer size="lg" class="animationOpacity"
           no-close-on-backdrop
  >
    <b-row class="quiz">
      <div class="modalLateralMidiaFundo" >
        <div class="modalLateralMidia animationMenu2 animationOpacity">
        </div>
      </div>
      <b-col cols="12" md="10" class="mx-auto pt-1 mb-5">
        <div class="d-block">
          <div class="container-title-header">
            <div class="title">Questionário</div>
            <div class="lineDiv mt-3 mb-3 col-md-12 col-12"></div>
          </div>
          <div>
            <div class="mt-3">
              <label>Qual e a questão?</label>
              <b-form-textarea
                  id="textarea"
                  v-model.trim="dataQuestion.text"
                  placeholder="Questão"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </div>

            <button
                class="btn btn-add-options p-3 w-100 mt-3 mb-3"
                @click="addAlternative">Adicione alternativa</button>
          </div>
          <draggable
              class="list-group"
              ghost-class="ghost"
              @end="onEnd"
              :disabled="!inEdit"
          >
            <div class="container-options"
                 v-for="(option, index) in dataQuestion.answers"
                 :data-id="option.id"
                 :data-index="index"
                 :class="{'cursor-pointer': inEdit}"
            >

                <b-form-group  :label="'Alternativa '+(index+1)">
                  <b-form-radio name="is_correct"
                      v-b-tooltip.hover title="Alternativa correta?"
                      v-model="option.is_correct"
                      @change="isCorrect(index)"
                      :value="getValueRaio(option)"></b-form-radio>

                  <div class="input-group mb-2 ml-0" style="width: 95%;">
                    <input type="text"
                       class="form-control input-options"
                       v-bind:placeholder="'Opção '+(index+1)"
                       v-model.trim="option.text"
                       v-on:keyup.enter="addAlternative"
                    >
                    <div class="input-group-prepend">
                      <div class="input-group-text btn-remove-option"
                           @click="removeOption(index, option)">
                        <button type="button" class="close">×</button>
                      </div>
                    </div>
                  </div>
                </b-form-group>
            </div>
          </draggable>
          </div>
        <button
            class="btn btn-finish  p-3 w-100 mt-3"
            @click="addedQuestion"
            v-if="activateToTerminate()">Finalizar</button>

      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import notify from "@/services/libs/notificacao";
import CourseService from "@/services/resources/CourseService";
import draggable from "vuedraggable";

const serviceCourse = CourseService.build();

export default {
  props:{
    dataQuestion: {
      required: false
    },
    inEdit: {
      required: false
    }
  },
  components:{
    draggable
  },
  data() {
    return {
      quiz: {
        questions: []
      },
    };
  },
  methods: {
    onEnd: function (e) {

      var array = [];
      var lessons = document.querySelectorAll(".container-options");

      for (let i = 0; i < lessons.length; i++) {
        const option = lessons[i].dataset;
        array.push(parseInt(option.id));
      }

      const cursoId = this.$route.params.curso;
      const moduleId = this.$route.params.module;

      let data = {
        id: cursoId+"/module/" +moduleId+ "/lesson/"+this.dataQuestion.lesson_id+"/question/"+this.dataQuestion.id+"/answer/order",
        array,
      };
      this.$root.$emit("loadOn");
      serviceCourse
          .postIDArray(data)
          .then((resp) => {

            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
          });
    },
    getValueRaio(option){
      if(typeof option.is_correct === 'boolean'){
        return true
      }
      return 1
    },
    addAlternative(){
      if(!this.dataQuestion.text){
        notify("erro", "Por favor informe a questão!");
        return false;
      }

      let valid = this.dataQuestion.answers.filter(item=>{
        if(!item.text){
          return item;
        }
      })

      if(valid.length){
        notify("erro", "Por favor informe a alternativa!");
        return false
      }

      this.dataQuestion.answers.push({
        text: null, is_correct: false
      })

    },
    async removeOption(item, option){

      if(option.id){
        await serviceCourse.delete(
            `${option.course_id}/module/${option.module_id}/lesson/${option.lesson_id}/question/${option.question_id}/answer/${option.id}`
        ).then(resp=>{
          //console.log(resp)
          this.dataQuestion.answers.find((dataOption, index)=>{
            if(dataOption.id === option.id){
              //console.log('this.dataQuestion.answers[index]', this.dataQuestion.answers[index])
              delete this.dataQuestion.answers[index]
              return dataOption;
            }
          })
        }).catch(e=>{
          //console.log(e)
          notify("erro", "Falha ao remover alternativa!");
          return false
        })


      }else{
        delete this.dataQuestion.answers[item]
      }

      if(this.dataQuestion.answers.length === 1){
        this.dataQuestion.answers = []
      }

      let dataOptions = []
      this.dataQuestion.answers.forEach(item=>{
        dataOptions.push(item)
      })
      //console.log('this.dataQuestion.answers = dataOptions',this.dataQuestion.answers)
      this.dataQuestion.answers = dataOptions
      //console.log('this.dataQuestion.answers', this.dataQuestion.answers)
    },
    activateToTerminate(){
      return this.dataQuestion.answers && this.dataQuestion.answers.length >= 2
    },
    addedQuestion(){

      let is_correct =  this.dataQuestion.answers.filter((item, index)=>{
        if(item.is_correct){
          return item
        }
      })
      if(!is_correct.length){
        notify("erro", "Por favor informe qual alternativa e a correta!");
        return
      }

      this.$bvModal.hide('modal-view-quiz')
      this.$emit('addedQuestion', {questions: [this.dataQuestion]});

    },
    isCorrect(itemOption){
      if(!this.dataQuestion.answers.length){
        return false
      }
      this.dataQuestion.answers.forEach((item, index)=>{
        this.dataQuestion.answers[index].is_correct = false;
      })
      this.dataQuestion.answers[itemOption].is_correct = true;
    },
  },
  mounted() {

  }
};
</script>
<style lang="scss">

.btn-add-options{
  color: var(--fontcolor) !important;
}

#modal-view-quiz___BV_modal_header_{
  border-bottom: 0 !important;
}

.container-options>fieldset>div{
  display: flex;
}

.input-options{
  width: 95%;
}

.btn-add-options {
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--maincolor);
}
.btn-add-options:hover{
  color: var(--maincolor);
}
.btn-finish {
  background: var(--maincolor);
  border-radius: 5px;
  height: 55px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
.btn-finish:hover{
  color: #ffffff;
}

textarea, input{
  border: 1px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ededf0;
  overflow: hidden !important;
  resize: none !important;
  background: white;
}

.btn-remove-option{
  font-size: inherit !important;
  border: 0 !important;
  background-color: #fff  !important;
  position: absolute;
  right: 3px;
  height: 95%;
  top: 1px;
}

input {
  height: 55px !important;
  font-size: 14px;
}

input:hover,
input:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}
input:hover .btn-remove-option{
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}

input:focus .btn-remove-option{
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}

input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  color: #000000;
  height: 55px;
}

.questao,.deletar,.editar{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.questao{
  color: #2E31E8FF;
}

.deletar{
  color: #FF0C37;
}
.editar{
  color: #81858E;
}

.custom-radio{
  width: 5% !important;
  display: inline-block !important;
  vertical-align: sub;
}

.container-title-header{
  width: 100%;
}

.lineDiv {
  height: 0.5px;
  background: #e5e5e5;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: var(--fontcolor) !important;
}

#modal-quiz .modal-header {
  border: none !important;
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
#modal-quiz .tabs-upload.active .text {
  color: var(--maincolor);
}
#modal-quiz .tabs-upload.active path {
  stroke: var(--maincolor) !important;
}
.cursor-pointer{
  cursor: pointer;
}
.tosearch {
  .inputSearch {
    position: relative;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 450px;
  }

  .input-busca {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: solid 1px var(--bordercolor);
    border-radius: 5px;
    padding: 20px 30px;
    font-family: Montserrat;
    font-weight: normal;
    color: #81858e;
    font-size: 14px;
    transition: 0.3s;
  }

  .input-busca:hover,
  .input-busca:focus {
    box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
  }

  .search {
    position: absolute;
    right: 25px;
    top: 24px;
    width: 14.7px;
    height: 14.7px;
    z-index: 999;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }
}

.spaceVideoModal {
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor2);
}

.close {
  color: var(--icon) !important;
}
</style>