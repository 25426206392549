<template>
  <b-modal id="modal-quiz" hide-footer size="lg" no-close-on-backdrop class="animationOpacity">
    <b-row class="quiz">
      <div class="modalLateralMidiaFundo">
        <div class="modalLateralMidia animationMenu2 animationOpacity">
        </div>
      </div>
      <b-col cols="12" md="10" class="mx-auto pt-1 mb-5">
        <div class="d-block">
          <div class="container-title-header">
            <div class="title">Questionario</div>
            <div class="lineDiv mt-3 mb-3 col-md-12 col-12"></div>
          </div>
          <div>

            <div class="mt-3">
              <label>Qual e a questão?</label>
              <b-form-textarea id="textarea" v-model.trim="quiz.questions[indexQuestion].text" placeholder="Questão"
                rows="3" max-rows="6"></b-form-textarea>
            </div>

            <button class="btn btn-add-options p-3 w-100 mt-3"
              v-if="quiz.questions[indexQuestion] && quiz.questions[indexQuestion].text" @click="addAlternative">Adicione
              alternativa</button>

            <div class="mt-3 container-options" v-for="(item, index) in quiz.questions[indexQuestion].answers"
              :key="index">
              <b-form-group :label="'Alternativa ' + (index + 1)">
                <b-form-radio name="is_correct" v-b-tooltip.hover title="Alternativa correta?" @change="isCorrect(index)"
                  :value="false"></b-form-radio>

                <div class="input-group mb-2 ml-0" style="width: 95%;">
                  <input type="text" class="form-control input-options" v-bind:placeholder="'Opção ' + (index + 1)"
                    v-model.trim="quiz.questions[indexQuestion].answers[index].text" v-on:keyup.enter="addAlternative">
                  <div class="input-group-prepend">
                    <div class="input-group-text btn-remove-option" @click="removeOption(indexQuestion, index)">
                      <button type="button" class="close">×</button>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
            <button class="btn btn-finish  p-3 w-100 mt-3" @click="addedQuestion" v-if="activateToTerminate">
              Finalizar
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import notify from "@/services/libs/notificacao";

export default {
  props: ['inEdit'],
  data() {
    return {
      question: null,
      quiz: {
        questions: [{
          text: null,
          index: 0,
          answers: []
        }]
      },
      indexQuestion: 0,
    };
  },
  computed: {
    activateToTerminate() {
      return this.quiz?.questions[this.indexQuestion]?.answers?.length >= 2 || null;
    },
  },
  methods: {
    removeOption(indexQuestion, item) {

      delete this.quiz.questions[indexQuestion].answers[item]

      if (this.quiz.questions[indexQuestion].answers.length === 1) {
        this.quiz.questions[indexQuestion].answers = []
      }

      let dataOptions = []
      this.quiz.questions[indexQuestion].answers.forEach(item => {
        dataOptions.push(item)
      })

      this.quiz.questions[indexQuestion].answers = dataOptions
    },
    addAlternative() {
      if (!this.quiz.questions[this.indexQuestion].text) {
        notify("erro", "Por favor informe a questão!");
        return false;
      }

      let valid = this.quiz.questions[this.indexQuestion].answers.filter(item => {
        if (!item.text) {
          return item;
        }
      })

      if (valid.length) {
        notify("erro", "Por favor informe a alternativa!");
        return false
      }

      this.quiz.questions[this.indexQuestion].answers.push({
        text: null, is_correct: false
      })
    },
    isCorrect(itemOption) {
      if (!this.quiz.questions[this.indexQuestion].answers.length) {
        return false
      }
      this.quiz.questions[this.indexQuestion].answers.forEach((item, index) => {
        this.quiz.questions[this.indexQuestion].answers[index].is_correct = false;
      })
      this.quiz.questions[this.indexQuestion].answers[itemOption].is_correct = true;
    },
    addedQuestion() {
      let is_correct = this.quiz.questions[this.indexQuestion].answers.filter((item, index) => {
        if (item.is_correct) {
          return item
        }
      })
      if (!is_correct.length) {
        notify("erro", "Por favor informe qual alternativa e a correta!");
        return
      }
      this.quiz.questions[this.indexQuestion].index = this.indexQuestion
      let dataQuestion = Object.assign({}, this.quiz);
      let newItem = this.quiz.questions[this.indexQuestion];
      this.quiz.questions.push({
        text: null,
        index: this.indexQuestion + 1,
        answers: []
      })
      this.indexQuestion++;
      this.$bvModal.hide('modal-quiz')
      if (this.inEdit) {
        this.$emit('addedQuestion', { questions: [newItem] });
      } else {
        this.$emit('addedQuestion', dataQuestion);
      }
    }
  }
};
</script>
<style lang="scss">
.container-options>fieldset>div {
  display: flex;
}

.input-options {
  width: 95%;
}

.btn-add-options {
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--maincolor);
}

.btn-add-options:hover {
  color: var(--maincolor);
}

.btn-finish {
  background: var(--maincolor);
  border-radius: 5px;
  height: 55px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.btn-finish:hover {
  color: #ffffff;
}

textarea,
input {
  border: 1px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ededf0;
  overflow: hidden !important;
  resize: none !important;
  background: white;
}

.btn-remove-option {
  font-size: inherit !important;
  border: 0 !important;
  background-color: #fff !important;
  position: absolute;
  right: 3px;
  height: 95%;
  top: 1px;
}

input {
  height: 55px !important;
  font-size: 14px;
}

input:hover,
input:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}

input:hover .btn-remove-option {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}

input:focus .btn-remove-option {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  height: 55px;
}

input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--maincolor);
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  color: #000000;
  height: 55px;
}

.questao,
.deletar,
.editar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.questao {
  color: #2E31E8FF;
}

.deletar {
  color: #FF0C37;
}

.editar {
  color: #81858E;
}

.custom-radio {
  width: 5% !important;
  display: inline-block !important;
  vertical-align: sub;
}

.container-title-header {
  width: 100%;
}

.lineDiv {
  height: 0.5px;
  background: #e5e5e5;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: var(--fontcolor) !important;
}

#modal-quiz .modal-header {
  border: none !important;
}

@keyframes spinning {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}

#modal-quiz .tabs-upload.active .text {
  color: var(--maincolor);
}

#modal-quiz .tabs-upload.active path {
  stroke: var(--maincolor) !important;
}

.tosearch {
  .inputSearch {
    position: relative;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 450px;
  }

  .input-busca {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: solid 1px var(--bordercolor);
    border-radius: 5px;
    padding: 20px 30px;
    font-family: Montserrat;
    font-weight: normal;
    color: #81858e;
    font-size: 14px;
    transition: 0.3s;
  }

  .input-busca:hover,
  .input-busca:focus {
    box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
  }

  .search {
    position: absolute;
    right: 25px;
    top: 24px;
    width: 14.7px;
    height: 14.7px;
    z-index: 999;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
  }
}

.spaceVideoModal {
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor2);
}

.close {
  color: var(--icon) !important;
}
</style>